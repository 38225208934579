<template>
	<div
		data-component="bid"
		:data-type="getType"
		:data-background-colour="getBackgroundColour"
	>
		<div data-element="value">
			{{ getValue }}
		</div>
		<suit-symbol
			v-if="getShowSuitSymbol"
			:suitName="getSuit"
			:withoutPadding="true"
		/>
	</div>
</template>

<script>

	import SuitSymbol from '@/components/diagrams/SuitSymbol';

	export default {
		components: {
			SuitSymbol
		},
		props: {
			bid: {
				type: Object,
				required: true
			},
			showBackgroundColour: {
				type: Boolean,
				default: false
			},
			showLongPass: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			getBackgroundColour () {
				if (!this.bid) {
					return false;
				}
				if (!this.showBackgroundColour) {
					return 'white';
				}
				if (this.bid.type === 'pass') {
					return 'green';
				}
				if (this.bid.type === 'double') {
					return 'red';
				}
				if (this.bid.type === 'redouble') {
					return 'blue';
				}
				return 'white';
			},
			getType () {
				return this.bid.type;
			},
			getValue () {
				if (this.bid.type === 'pass') {
					if (this.showLongPass) {
						return 'Pass';
					}
					return 'P';
				}
				if (this.bid.type === 'double') {
					return 'X';
				}
				if (this.bid.type === 'redouble') {
					return 'XX';
				}
				if (this.bid.suit === 'NT') {
					return this.bid.value + 'NT';
				}
				return this.bid.value;
			},
			getSuit () {
				return this.bid?.suit;
			},
			getShowSuitSymbol () {
				if (!this.getSuit) {
					return false;
				}
				return this.getSuit !== 'NT';
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='bid'] {
		display:flex;
		align-items:center;
		justify-content:center;
		background-color:$c-white;
		[data-element='value'] {
			text-transform:uppercase;
		}
		&[data-background-colour='green'] {
			background-color:$c-brand-green-lighter-2;
			[data-element='value'] {
				color:$c-black;
			}
		}
		&[data-background-colour='red'] {
			background-color:$c-brand-red-lighter-2;
			[data-element='value'] {
				color:$c-black;
			}
		}
		&[data-background-colour='blue'] {
			background-color:$c-brand-blue-lighter-2;
			[data-element='value'] {
				color:$c-black;
			}
		}
	}

</style>
