<template>
	<div
		data-component="auction"
		v-if="getIsVisible"
		:data-size="getSize"
	>
		<div
			v-if="getBids"
			data-element="bids"
			:data-is-quiz="isPartOfQuiz"
			ref="bids"
		>
			<bid-button
				v-for="(bid, i) of getBids"
				:key="i"
				:bid="bid"
				:uuid="uuid"
				:isEditMode="isEditMode"
				:isValid="isValid"
				:isThumbnail="isThumbnail"
				:isPartOfQuiz="isPartOfQuiz"
				:isAnswerDiagram="isAnswerDiagram"
			/>
		</div>
		<div data-element="special-bids">
			<bid-button
				:bid="getPass"
				:uuid="uuid"
				:isEditMode="isEditMode"
				:isValid="isValid"
				:isThumbnail="isThumbnail"
				:isPartOfQuiz="isPartOfQuiz"
				:isAnswerDiagram="isAnswerDiagram"
				:showLongPass="true"
			/>
			<bid-button
				:bid="getDouble"
				:uuid="uuid"
				:isEditMode="isEditMode"
				:isValid="isValid"
				:isThumbnail="isThumbnail"
				:isPartOfQuiz="isPartOfQuiz"
				:isAnswerDiagram="isAnswerDiagram"
			/>
			<bid-button
				:bid="getReDouble"
				:uuid="uuid"
				:isEditMode="isEditMode"
				:isValid="isValid"
				:isThumbnail="isThumbnail"
				:isPartOfQuiz="isPartOfQuiz"
				:isAnswerDiagram="isAnswerDiagram"
			/>
		</div>
	</div>
</template>

<script>

	import BidButton    from '@/components/simulator/bidding/BidButton';
	import gameService  from '@/services/gameService';

	export default {
		components: {
			BidButton
		},
		props: {
			uuid: {
				type: String,
				default: undefined
			},
			isEditMode: {
				type: Boolean,
				default: false
			},
			isThumbnail: {
				type: Boolean,
				default: false
			},
			isAnswerDiagram: {
				type: Boolean,
				default: false
			},
			isValid: {
				type: Boolean,
				default: true
			},
			errors: {
				type: Object,
				default: undefined
			},
			isPartOfQuiz: {
				type: Boolean,
				default: false
			}
		},
		data: () => ({
		}),
		asyncComputed: {
			getDiagramIsInModal () {
				return this.$store.getters['quiz/getDiagramIsInModalByUuid']({
					uuid: this.uuid
				});
			},
			async getGameData () {
				if (!this.isPartOfQuiz) {
					return false;
				}
				if (!this.getDiagramIsInModal &&
						!this.getCurrentBid) {
					return this.getNewGame({ bids: '' });
				}
				try {
					if (this.errors &&
							this.errors[`currentBid-${this.uuid}`] &&
							this.errors[`currentBid-${this.uuid}`].length) {
						return this.getNewGame({ bids: '' });
					}
					return this.getNewGame({
						bids: [this.getCurrentBid]
					});
				} catch (error) {
					return this.getNewGame({ bids: '' });
				}
			},
			getCurrentBid () {
				if (!this.isPartOfQuiz) {
					return false;
				}
				return this.$store.getters['quiz/getCurrentBidByUuid']({
					uuid: this.uuid
				});
			},
			getPass () {
				const pass = {
					bid: 'P',
					type: 'pass'
				};
				if (this.isPartOfQuiz) {
					return {
						...pass,
						isValid: true
					};
				}
				return {
					...pass,
					isValid: this.$store.getters['simulator/getCanPass']
				};
			},
			getDouble () {
				const double = {
					bid: 'X',
					type: 'double'
				};
				if (this.isPartOfQuiz) {
					return {
						...double,
						isValid: true
					};
				}
				return {
					...double,
					isValid: this.$store.getters['simulator/getCanDouble']
				};
			},
			getReDouble () {
				const reDouble = {
					bid: 'XX',
					type: 'redouble'
				};
				if (this.isPartOfQuiz) {
					return {
						...reDouble,
						isValid: true
					};
				}
				return {
					...reDouble,
					isValid: this.$store.getters['simulator/getCanReDouble']
				};
			},
			getIsVisible () {
				if (this.isPartOfQuiz) {
					return true;
				}
				return this.$store.getters['simulator/getAuctionIsVisible'];
			},
			async getBids () {
				if (this.isPartOfQuiz) {
					if (!(await this.getGameData)?.bidOptions?.contract) {
						return false;
					}
					return (await this.getGameData).bidOptions.contract;
				}
				return this.$store.getters['simulator/getPossibleBids'];
			},
			getSize () {
				if (this.isPartOfQuiz) {
					return 'lg';
				}
				return 'md';
			}
		},
		mounted () {
			this.scrollToBottomRow();
		},
		methods: {
			async getNewGame ({ bids }) {
				return gameService.getNewGameFromGameState({
					gameState: {
						name: 'Bidding table',
						deal: 'N:',
						auction: {
							value: 'N',
							bids
						}
					}
				});
			},
			scrollToRow (rowToScrollTo) {
				this.$nextTick(() => {
					if (!this.$refs.bids) {
						return false;
					}
					console.log('rowToScrollTo', rowToScrollTo);
					const bidsHeight = this.$refs.bids.scrollHeight;
					console.log('bidsHeight', bidsHeight);
					const bidHeight = bidsHeight / 7;
					console.log('bidHeight', bidHeight);
					const amountToScroll = rowToScrollTo * bidHeight;
					console.log('amountToScroll', amountToScroll);
					const scrollTop = bidsHeight - amountToScroll - (bidHeight * 3);
					console.log('scrollTop', scrollTop);
					this.$refs.bids.scrollTop = scrollTop;
				});
			},
			scrollToBottomRow () {
				if (!this.getCurrentBid ||
						this.getCurrentBid === 'P' ||
						this.getCurrentBid === 'X' ||
						this.getCurrentBid === 'XX') {
					return this.scrollToRow(1);
				}
				this.scrollToRow(this.getCurrentBid[0]);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='auction'] {
		width:rem(140);
		background-color:$c-white;
		[data-element='bids'] {
			width:100%;
			display:flex;
			flex-wrap:wrap;
			&[data-is-quiz] {
				height:rem(28 * 4);
				overflow-y:scroll;
				// foreground then background
				scrollbar-color: #999 #333;
				&::-webkit-scrollbar {
					width: 5px;
				}
				&::-webkit-scrollbar-thumb {
					// foreground
					background: $c-brand-blue;
				}
				&::-webkit-scrollbar-track {
					// background
					background: $c-brand-blue-lighter-4;
				}
			}
		}
		::v-deep [data-component='bid-button'] {
			width:20%; height:rem(22);
			&[data-value='1'] {
				order:7;
			}
			&[data-value='2'] {
				order:6;
			}
			&[data-value='3'] {
				order:5;
			}
			&[data-value='4'] {
				order:4;
			}
			&[data-value='5'] {
				order:3;
			}
			&[data-value='6'] {
				order:2;
			}
			&[data-value='7'] {
				order:1;
			}
			[data-component='bid'] {
				flex-grow:1;
				margin:0;
				height:100%;
				width:100%;
				[data-element='value'] {
					margin-right:0.1em;
					font-size:rem(13);
					line-height:1;
					font-weight:700;
				}
				[data-component='suit-symbol'] {
					[data-component='icon'] {
						width:auto; height:rem(9);
					}
				}
			}
		}
		[data-element='special-bids'] {
			width:100%;
			display:flex;
			::v-deep [data-component='bid-button'] {
				&[data-type='pass'] {
					width:50%;
				}
				&[data-type='double'],
				&[data-type='redouble']{
					width:25%;
				}
			}
		}
		&[data-size='lg'] {
			width:rem(180);
			::v-deep [data-component='bid-button'] {
				height:rem(28);
				[data-component='bid'] {
					[data-element='value'] {
						font-size:rem(18);
					}
					[data-component='suit-symbol'] {
						[data-component='icon'] {
							width:auto; height:rem(13);
						}
					}
				}
			}
		}
	}

</style>
