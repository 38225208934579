<template>
	<div
		data-component="bid-button"
		:data-inactive="getIsInactive"
		:data-disabled="isDisabled"
		:data-busy="isBusy"
		:data-value="bid.value"
		:data-selected="getIsSelected"
		:data-highlighted="getIsBidHighlighted"
		:data-answer="isAnswerDiagram"
		:data-study-mode="isStudyMode"
		:data-selectable="getIsSelectable"
		:data-correct="isCorrect"
		:data-incorrect="!isCorrect"
		:data-selected-and-correct-and-quiz-is-complete="getIsSelectedAndCorrectAndQuizIsComplete"
		:data-selected-and-incorrect-and-quiz-is-complete="getIsSelectedAndIncorrectAndQuizIsComplete"
		:data-type="getType"
		@click="clickBid"
	>
		<bid
			:bid="bid"
			:showBackgroundColour="true"
			:showLongPass="showLongPass"
		/>
	</div>
</template>

<script>

	import Bid         from '@/components/diagrams/Bid';
	import quizAnswer  from '@/mixins/quizAnswer';

	export default {
		components: {
			Bid
		},
		mixins: [quizAnswer],
		props: {
			uuid: {
				type: String,
				default: undefined
			},
			isEditMode: {
				type: Boolean,
				default: false
			},
			isStudyMode: {
				type: Boolean,
				default: false
			},
			isThumbnail: {
				type: Boolean,
				default: false
			},
			isAnswerDiagram: {
				type: Boolean,
				default: false
			},
			isValid: {
				type: Boolean,
				default: true
			},
			bid: {
				type: Object,
				required: true
			},
			isPartOfQuiz: {
				type: Boolean,
				default: false
			},
			showLongPass: {
				type: Boolean,
				default: false
			},
			isDisabled: {
				type: Boolean,
				default: false
			},
			isBusy: {
				type: Boolean,
				default: false
			},
			isHighlighted: {
				type: Boolean,
				default: false
			},
			isCorrect: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			getIsValid () {
				return this.bid.isValid;
			},
			getIsInactive () {
				return !this.getIsValid;
			},
			getAnswerValue () {
				// overriding value in mixin
				return this.bid.bid;
			},
			getType () {
				return this.bid.type;
			},
			getIsBidHighlighted () {
				return this.isHighlighted || this.getIsHighlighted;
			}
		},
		methods: {
			clickBid () {
				if (
					!this.getIsValid ||
					this.isDisabled ||
					this.isBusy
				) {
					return false;
				}
				if (this.isStudyMode) {
					this.$emit('addBid', this.bid);
					return;
				}
				if (this.isPartOfQuiz) {
					this.selectAnswer();
					return;
				}
				this.$store.dispatch('simulator/addBid', {
					bid: this.bid
				});
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='bid-button'] {
		display:flex;
		margin:0;
		opacity:1;
		cursor:pointer;
		user-select:none;
		&[data-inactive],
		&[data-disabled] {
			opacity:0.3;
			cursor:default !important;
		}
		&[data-busy] {
			cursor:default !important;
		}
		&[data-answer] {
			cursor:default;
			border-radius:4px;
			::v-deep [data-component='bid'] {
				border:3px solid transparent;
			}
			&[data-study-mode] {
				cursor: pointer;
			}
			&[data-selectable] {
				cursor:pointer;
			}
			&[data-correct] {
				::v-deep [data-component='bid']:active {
					background-color: lighten($c-brand-green, 50%);
				}
			}
			&[data-incorrect] {
				::v-deep [data-component='bid']:active {
					background-color: lighten($c-brand-red, 50%);
				}
			}
			&[data-inactive] {
				cursor:default;
			}
			&[data-selected] {
				::v-deep [data-component='bid'] {
					background-color:lighten($c-brand-blue-lighter-1, 50%);
					border-color:$c-brand-blue-lighter-1;
					cursor:default;
					opacity:1;
				}
			}
			&[data-selected-and-correct-and-quiz-is-complete] {
				::v-deep [data-component='bid'] {
					background-color:lighten($c-brand-green, 50%);
					border-color:$c-brand-green;
				}
			}
			&[data-selected-and-incorrect-and-quiz-is-complete] {
				::v-deep [data-component='bid'] {
					background-color:lighten($c-brand-red, 50%);
					border-color:$c-brand-red;
					&[data-selectable] {
						cursor:pointer;
					}
				}
			}
			&[data-highlighted] {
				::v-deep [data-component='bid'] {
					border-color:$c-brand-green;
				}
			}
		}
	}

</style>
